<template>
    
    <breadcrumb  :home="home" :model="britems" />
    <div class="grid card">
        <Toast/>        
        <div class="col-4">
            
            <div class="grid">
                    
                        <div class="col-10 h-full w-full m-0  px-4">
                            <div class="text-5xl" style="text-align:center;color:orangered">Speed Networking</div>                                               
                        </div>
                        <div class="col-10 h-full mb-8  ml-9">
                            <lottie-player
                                autoplay
                                loop
                                mode="normal"
                                speed="1.0"
                                src="https://assets4.lottiefiles.com/packages/lf20_98vgucqb.json"
                                >
                            </lottie-player>
                        </div>                                               
            </div>
                    
        </div>
        
        <div class="col-6">
            <div v-if="loading" id="loading">
                <div class="loader col-8"></div>
            </div>
            
            <div class="grid p-fluid">
                <div class="col-12 md:col-8">
                    <h3>Create Event</h3>
                </div>                
            </div>
            <hr/>
            <!-- <div class="grid p-fluid">
                <div class="col-12 md:col-8">
                    <Dropdown v-model="dropdownValue" :options="dropdownValues" optionLabel="name" style="background-color: lightgrey;" placeholder="Time Zone" >
                        <template #value="slotProps">
                            <div  class="p-dropdown-car-value" v-if="slotProps.value">
                                <span>{{"Time Zone: "+slotProps.value.name}}</span>
                            </div>
                            <span v-else>
                                {{slotProps.placeholder}}
                            </span>
                        </template>                        
                    </Dropdown>    
                </div>  
            </div>
            <hr/> -->
            <div class="p-fluid">
                        
                        <div class="field grid" style="margin-bottom:2rem;">
                            <div class="col-12">
                                <div class="grid">
                                    <div class="col-2 mt-2">
                                        <h6 style="text-align: left;" class="required">Title</h6>                                             
                                    </div>
                                    <div class="col-9">
                                        <InputText id="name" v-model.trim="product.event_name" required="true" autofocus :class="{'p-invalid': submitted && !product.event_name}" />
                                        <small class="p-invalid" v-if="submitted && !product.event_name">Name is required.</small>
                                    </div>
                                </div>
                            </div>            
                        </div>
                        <div style="display: none;" class="field">
                            <label class="required" style="font-size: medium;" for="admin_emails">Co Host Email (If you are not the event creator)</label>
                            <InputText id="admin_emails" v-model.trim="product.co_host_by" required="true"  autofocus :class="{'p-invalid': submitted && !product.co_host_by}" />
                            <small class="p-invalid" v-if="submitted && !product.co_host_by">Emails is required.</small>
                        </div>
                        <hr/>
                        <div class="field grid" style="margin-bottom:2rem;">
                            <div class="col-12 mt-2">
                                <div class="grid">
                                    <div class="col-2 mt-2">
                                        <h6 style="text-align: left;" class="required">Required</h6>                                             
                                    </div>
                                    <div class="col-9">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-user" ></i>
                                            <AutoComplete placeholder="Add required attendees" id="invitees" :dropdown="true" :multiple="true" v-model="selectedAutoValue" :suggestions="autoFilteredValue" @complete="searchCountry($event)" field="displayName"/>
                                        </span>
                                        <small style="color:red;" class="p-invalid" v-if="submitted && !selectedAutoValue">Required.</small>
                                     </div>
                                </div>
                            </div>            
                        </div>


                    

                        <hr/>
                        <div class="field grid" style="margin-bottom:2rem;">
                            <div class="col-12 mt-2">
                                <div class="grid">
                                    <div class="col-2 mt-2">
                                        <h6 style="text-align: left;" class="required">Start Time</h6>                                             
                                    </div>
                                    <div class="col-3">
                                        <Calendar v-model="product.start_date" :minDate="minDate"  :disabledDays="[0,6]"/>

                                        <small class="p-invalid" v-if="submitted && !product.start_date">Start Time is required.</small>
                                    </div>
                                    <div class="col-2">
                                        <Dropdown v-model="dropdowntimevalue" :options="dropDownTimeValues" optionLabel="name">
                                                                 
                                        </Dropdown>
                                    </div>
                                    <div class="col-4">
                                        <Dropdown v-model="dropdownValue" :options="dropdownValues"  >
                                            <!-- <template #value="slotProps">
                                                <div  class="p-dropdown-car-value" v-if="slotProps.value">
                                                    <span>{{"Time Zone: "+slotProps.value.name}}</span>
                                                </div>
                                                <span v-else>
                                                    {{slotProps.placeholder}}
                                                </span>
                                            </template>                         -->
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                            <!-- <div class="field">
                                <label for="body">Body</label>
                                <Editor id="body" v-model="product.body" required="true" editorStyle="height: 200px" autofocus :class="{'p-invalid': submitted && !product.body}"/>
                                <small class="p-invalid red" v-if="submitted && !product.body">Body is required.</small>

                            </div>                        -->   
                            <hr/> 
                            <div class="grid p-fluid">
                                <div class="col-12 md:col-3">
                                    <Button label="Create Event" icon="pi pi-check" class="p-button-raised p-button-rounded" @click="saveProduct" />
                                </div>
                                <div class="col-12 md:col-2">
                                    <Button label="Cancel" icon="pi pi-times" class="p-button-raised p-button-rounded p-button-secondary" @click="cancel"/>
                                </div>
                            </div>                        
                       
                        
            </div>
        </div>  
         
    </div>
</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';
import SNService from "../../../service/SNService";
import ADSearchService from "../../../service/ADSearchService";
import TimeZoneService from "../../../service/TimeZoneService";
import "@lottiefiles/lottie-player";
export default {
	data() {
		return {    
            home: {icon: 'pi pi-home', to: '/'},
            britems: [
				{label: 'Create Event',disabled:true},
            ],        
			product: {event_name:'SpeedNetworking - '},
            autoFilteredValue: [],
            selectedAutoValue: null,
            account:{},
            submitted:false,
            loading:false,
            settings:{},
            dropDownTimeValues:[{name: '00:00', code: 0},{name: '00:30', code: '30'},{name: '01:00', code: '60'},{name: '01:30', code: '90'},
                                {name: '02:00', code: '120'},{name: '02:30', code: '150'},{name: '03:00', code: '180'},{name: '03:30', code: '210'},
                                {name: '04:00', code: '240'},{name: '04:30', code: '270'},{name: '05:00', code: '300'},{name: '05:30', code: '330'},
                                {name: '06:00', code: '360'},{name: '06:30', code: '390'},{name: '07:00', code: '420'},{name: '07:30', code: '450'},
                                {name: '08:00', code: '480'},{name: '08:30', code: '510'},{name: '09:00', code: '540'},{name: '09:30', code: '570'},
                                {name: '10:00', code: '600'},{name: '10:30', code: '630'},{name: '11:00', code: '660'},{name: '11:30', code: '690'},
                                {name: '12:00', code: '720'},{name: '12:30', code: '750'},{name: '13:00', code: '780'},{name: '13:30', code: '810'},
                                {name: '14:00', code: '840'},{name: '14:30', code: '870'},{name: '15:00', code: '900'},{name: '15:30', code: '930'},
                                {name: '16:00', code: '960'},{name: '16:30', code: '990'},{name: '17:00', code: '1020'},{name: '17:30', code: '1050'},
                                {name: '18:00', code: '1080'},{name: '18:30', code: '1110'},{name: '19:00', code: '1140'},{name: '19:30', code: '1170'},
                                {name: '20:00', code: '1200'},{name: '20:30', code: '1230'},{name: '21:00', code: '1260'},{name: '21:30', code: '1290'},
                                {name: '22:00', code: '1320'},{name: '22:30', code: '1350'},{name: '23:00', code: '1380'},{name: '23:30', code: '1410'},
                            ],
            dropdowntimevalue:{name: '00:00', code: 0},
            dropdownValue: '',
            dropdownValues: [],
            utcTimeZone:{},
            minDate:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()+4),
            utctozonekey : {}
            
		}
	},
    searchService:null,
    async created(){
        this.$msalInstance = new PublicClientApplication(this.$store.state.msalConfig);
        this.srateService = new SNService();
        this.searchService = new ADSearchService();
        this.timeZoneService= new TimeZoneService();
        //console.log(new Date().toLocaleString(undefined, {timeZone: 'Asia/Kolkata'}));
    },
	mounted() {
        const accounts = this.$msalInstance.getAllAccounts();
		if (accounts.length == 0) {
		return;
		}
		this.account = accounts[0];	
        this.product = {event_name:'SpeedNetworking -', co_host_by:this.account.username,
                        created_by:this.account.username};
        this.loading = true;
        this.srateService.usersettings().then((res) => {
                                    console.log(res);
                                }).catch(error => {
                                    console.log(error);
                                }).finally(()=> {
                                    this.loading = false;
                                }); 	
        //this.dropdownValues = this.timeZoneService.getAllTimeZone();  

        let utcT= this.timeZoneService.getUTCTimeZone();
        for (let i=0;i<utcT.length;i++){
            this.dropdownValues.push(utcT[i].text);
            for(let j=0;j<utcT[i].utc.length;j++){
                this.utcTimeZone[utcT[i].utc[j]]= utcT[i].text;
                this.utctozonekey[utcT[i].text]=utcT[i].offset;
                
            }
            
        }
        this.dropdownValue= this.utcTimeZone[Intl.DateTimeFormat().resolvedOptions().timeZone];
       

    },
	methods: {		
		cancel() {
            this.$router.push('/');
        },
        saveProduct(){
            this.product.status="Active";
            this.submitted = true;
            
			if (this.product.event_name.trim() && 
				this.product.start_date && 
				this.selectedAutoValue.length>0 && 
				this.product.co_host_by.trim()
			) {
                let event_date=this.product.start_date;
                this.product.hours_minute =(this.dropdowntimevalue.name);
                this.product.timezone = this.utctozonekey[this.dropdownValue];
                this.product.start_date_string = event_date.getFullYear()  + "-" + (event_date.getMonth()+1) + "-" + event_date.getDate();
                //this.loading = true;
                let all_inv=[];
                for(let i=0;i<this.selectedAutoValue.length;i++){
                    all_inv.push({email:(this.selectedAutoValue[i].mail)});                    
                }
                this.product.event_emails=all_inv;
                this.loading = true;
				this.srateService.save(this.product)
                                .then((res) => {
                                    console.log(res);
                                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Record Saved!', life: 3000});
                                    this.$router.push('/sn');

                                }).catch(error => {
                                    console.log(error);
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 3000});
                                }).finally(()=> {
                                    this.loading = false;
                                }); 		
				
			}
        },
        searchCountry(event) {
            if (!event.query.trim().length) {
						this.autoFilteredValue = [...this.autoValue];
					}
					else {
                        if(event.query.trim().length>2){
                                this.searchService.get(event.query).then((res) => {
                                    let values = res.data;
                                    console.log(values);
                                    this.autoFilteredValue = values;
                                    console.log(this.selectedAutoValue);
                                });
                        }
						
					}
			}
		
	}
}
</script>

<style lang="scss" scoped>

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.points {
    font-size: medium;
    text-align: initial;
}

#loading {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  opacity: 0.7;
  background-color: #fff;
  z-index: 99;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}


.snimg {
    width: 100%; /* or any custom size */
    height: 100%; 
    object-fit: contain;
}
.no-r-pad {
    padding-right: 0rem;
}
.model-info {
    border-color: #0093bc;
    border-width: 1px;
    border-style: solid;
    min-height: 16rem;
    position: relative;
    div {
        min-height: 10rem;
    }
    button {
        display: block;
        margin: 0 auto;

        margin-top: 1rem;
        padding: 0.2rem 2rem 0.2rem 2rem;
    }
    a {
        bottom: 0.8rem;
    }
    h6 {
        color: #0093bc;
        font-weight: 600;
    }
}

div {
    text-align: justify;
    text-justify: inter-word;
}
.required:after {
    content:" *";
    color: red;
  }
</style>
