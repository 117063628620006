import BaseService from './BaseService';

export default class ADSearchService extends BaseService{


    constructor(){
        super();
    }

	get(keyword) {
		return this.getAxios().get('/api/search/'+keyword)
    }
    
}
